export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/plan": [5],
		"/settings": [6],
		"/testing/debug": [7,[2]],
		"/testing/dump": [8,[2]],
		"/testing/generate": [9,[2]],
		"/testing/ios": [10,[2]],
		"/testing/ios/tabbar": [11,[2]],
		"/testing/offline": [12,[2]],
		"/testing/placehold-caching": [13,[2]],
		"/testing/supabase-upload": [~14,[2]],
		"/testing/unleash": [15,[2,3]],
		"/testing/unleash/direct": [16,[2,3]],
		"/testing/unleash/preloader": [17,[2,3]],
		"/timers": [18]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';